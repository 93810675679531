<template>
  <div class="empty-info__container">
    <div class="empty-info__message" v-html="message" />
    <slot name="description" />
    <button v-if="buttonText.length > 0" class="empty-info__button" @click="buttonAction">
      {{ buttonText }}
    </button>
    <slot name="button" />
  </div>
</template>
<script>
import './index.scss';
export default {
  name: 'NoInformation',
  props: {
    message: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonAction: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
