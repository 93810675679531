<template>
  <section class="layout-with-bar">
    <div class="layout-with-bar__main orders">
      <LoaderDots v-if="$fetchState.pending" />
      <template v-else>
        <ul v-if="orders.length" class="orders__list" :class="{ 'orders__list_pending' : isOrdersPending}">
          <li v-for="(orderItem, orderIdx) in orders" :key="orderIdx" class="orders__item">
            <OrderCardNew v-if="!mqIsMobile" :item="orderItem" @update:order="onUpdateOrder" />
            <OrderCardRedesigned v-if="mqIsMobile" :item="orderItem" @update:order="onUpdateOrder" />
<!--            <OrderCard :item="orderItem" @update:order="onUpdateOrder" />-->
          </li>
        </ul>
        <p v-else>
          <NoInformation message="Заказов нет" >
            <template #description>
              <div class="see-our">
                Посмотрите наши
                <nuxt-link to="/catalog/filters/badge-novinka/">новинки</nuxt-link> или воспользуйтесь
                <nuxt-link to="/search">поиском</nuxt-link>, если ищете что-то конкретное
              </div>
            </template>
            <template #button>
              <nuxt-link to="/catalog" class="go-to-catalog">Перейти к каталогу</nuxt-link>
            </template>
          </NoInformation>
        </p>

        <CustomPagination
          page-param="pageNum"
          :count="ordersCount"
          :per-page="orderPerPage"
          :preloader="isOrdersPending"
          :current-page="currentPage"
          :is-show-more="false"
          @showMore="onShowMore"
          @selected="pageSelectedHandler"
        />
      </template>
    </div>
    <div class="layout-with-bar__aside"></div>
  </section>
</template>

<script>
import LoaderDots from '@/components/elements/LoaderDots';
// import OrderCard from '@/components/profile/orderCard';
import OrderCardNew from '@/components/profile/orderCardNew';
import OrderCardRedesigned from '@/components/profile/orderCardRedesigned';
import pageMeta from '~/mixins/pageMeta';
import CustomPagination from '~/components/elements/CustomPagination';
import NoInformation from '~/components/profile/noInformation/index.vue';

export default {
  name: 'OrdersPage',
  components: {
    NoInformation,
    CustomPagination,
    LoaderDots,
    // OrderCard,
    OrderCardRedesigned,
    OrderCardNew,
  },
  mixins: [pageMeta],
  asyncData() {
    return {
      pageTitle: 'Заказы',
    };
  },
  data() {
    return {
      ordersParams: {
        sortDirection: 'desc',
        sortKey: 'number',
        pageNum: 1,
      },
      orders: [],
      ordersCount: null,
      isOrdersPending: false,

      orderPerPage: 12,
    };
  },
  async fetch() {
    try {
      const params = this.preparePageQuery({})
      const response = await this.$api.profile.getProfileOrders({
        ...this.ordersParams,
        ...params,
      });
      this.orders = response.orders;
      this.ordersCount = response.ordersCount;
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    currentPage() {
      return +this.$route.query?.pageNum || 1
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
  },
  methods: {
    async getOrders(params, showMore = true) {
      try {
        this.isOrdersPending = true;

        const response = await this.$api.profile.getProfileOrders({
          ...this.ordersParams,
          ...params,
        });

        if (showMore) {
          this.orders.push(...response.orders)
        } else {
          this.orders = response.orders;
        }

      } catch (error) {
        console.error(error);
      } finally {
        this.isOrdersPending = false;
      }
    },
    onShowMore(params) {
      this.updatePageQuery(params, true)
    },
    pageSelectedHandler(params) {
      this.updatePageQuery(params, false)
    },
    preparePageQuery(query) {
      const params = {
        ...this.$route.query,
        ...query,
      }

      params?.page === 1 && (delete params.page)

      return params
    },
    async updatePageQuery(query, showMore) {
      const params = this.preparePageQuery(query)

      this.$router.push({ path: this.$route.path, query: {...params} })

      await this.getOrders(params, showMore)
    },
    onUpdateOrder(order) {
      const { id } = order

      if (!id) return

      const orderIndex = this.orders.findIndex(i => i.id === id)

      if (orderIndex === -1) {
        return;
      }

      const updatedOrder = {
        ...this.orders[orderIndex],
        ...order,
      }

      this.$set(this.orders, orderIndex, updatedOrder)
    },
  },
};
</script>
<style scoped>
.see-our {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #282828;
  margin-top: 24px;
  a {
    color: #282828;
    text-decoration: underline;
  }
}
.go-to-catalog {
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #282828;
  margin-top: 24px;
  border: 1px solid #282828;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 50px;
}
</style>
