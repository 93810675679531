<template>
  <article class="profile-order-card">
    <div class="profile-order-card__top">
      <div class="profile-order-card__top-left">
        № {{ order.number }}
      </div>
      <div class="profile-order-card__top-right">
        {{ getCurrency(item.price.value) }}
      </div>
    </div>
    <div class="profile-order-card__date">
      {{ dateTimeToString(item.created_at, item.created_at) }}
    </div>
    <div class="profile-order-card__product-quantity">
      {{ products.length }} товар
    </div>
    <div class="profile-order-card__product-photos">
      <div v-for="product in products" :key="product.id" class="profile-order-card__product-photos-item">
        <img :src="product.image?.url?.small" :alt="product.name" />
      </div>
    </div>
    <div
      v-if="
        item.payment_status !== orderPaymentStatus.PAID &&
          !item.is_canceled && order.payment_method?.[0]?.data.paymentUrl"
      class="profile-order-card__product-bottom-row"
    >
      <Button class="profile-order-card__pay-button" @click="handlePayClick">
        Оплатить
      </Button>
    </div>
    <div class="profile-order-card__product-bottom-row">
      <div
        class="profile-order-card__product-status"
        :class="{
          'profile-order-card__product-status-not-paid':
            item.payment_status !== orderPaymentStatus.PAID &&
          !item.is_canceled && order.payment_method?.[0]?.data.paymentUrl
        }"
      >
        {{ paymentStatus }}
      </div>
      <div class="profile-order-card__product-expand">
        <button @click="handleExpandOrder(item.id)">
          <svg-icon v-if="!expanded" name="chevron-down-grey" width="24" height="24" />
          <svg-icon
            v-else
            name="chevron-down-grey"
            width="24"
            height="24"
            class="profile-order-card__product-expand-rotate-180"
          />
        </button>
      </div>
    </div>
    <div v-show="expanded" class="profile-order-card__product-info">
      <div v-for="delivery in deliveries" :key="delivery.id" class="profile-order-card__product-info-block">
        <div class="profile-order-card__info-main">
          <div class="profile-order-card__info-col">
            <div class="profile-order-card__info-title">
              Получатель
            </div>
            <div class="profile-order-card__info-desc"></div>
          </div>
          <div class="profile-order-card__info-col">
            <div class="profile-order-card__info-title">
              Метод доставки
            </div>
            <div class="profile-order-card__info-desc">
              {{ deliveryMethod(delivery.delivery_method) }}<br />
              {{ delivery.delivery_address.address_string }}
            </div>
          </div>
          <div class="profile-order-card__info-col">
            <div class="profile-order-card__info-title">
              Способ оплаты
            </div>
            <div class="profile-order-card__info-desc">
              {{ delivery.point?.payment }}
            </div>
          </div>
        </div>
        <div class="profile-order-card__info-content">
          <div class="profile-order-card__info-content-title">
            Состав заказа
          </div>
          <div class="profile-order-card__info-content-items">
            <div v-for="product in delivery.products" :key="product.id" class="profile-order-card__info-content-item">
              <div class="profile-order-card__info-content-left">
                <div class="profile-order-card__info-content-img">
                  <img :src="product.image?.url?.small" :alt="product.name">
                  <div class="profile-order-card__info-content-quantity">
                    {{ product.qty.slice(0, product.qty.indexOf('.')) }}
                  </div>
                </div>
                <div class="profile-order-card__info-content-text">
                  <div class="profile-order-card__info-content-item-title">
                    {{ product.name }}
                  </div>
                </div>
              </div>
              <div class="profile-order-card__info-content-right">
                {{ getCurrency(product.price.value) }}
              </div>
            </div>
          </div>
        </div>
        <div class="profile-order-card__info-footer">
          <div class="profile-order-card__info-footer-title">
            Информация о заказе
          </div>
          <div class="profile-order-card__info-footer-content">
            <div class="profile-order-card__info-footer-item">
              <div class="profile-order-card__info-footer-item-left">
                Сумма заказа
              </div>
              <div class="profile-order-card__info-footer-item-right">
                {{ getCurrency(order.price.value) }}
              </div>
            </div>
            <div class="profile-order-card__info-footer-item">
              <div>
                Скидка
              </div>
              <div class="profile-order-card__info-footer-item-right profile-order-card__info-footer-item-red">
              </div>
            </div>
            <div class="profile-order-card__info-footer-item">
              <div>
                Доставка
              </div>
              <div class="profile-order-card__info-footer-item-right">
                {{ getCurrency(order.delivery_price.value) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>

import {mapGetters} from 'vuex';
import {getOrderStatusName, getPaymentStatusName} from '~/plugins/helpers/order';
import {orderPaymentStatus, orderTypeName} from '~/plugins/enums/order';
import {dateTimeToString} from '~/plugins/formatting';
import './index.scss';
import {receiveMethods} from '~/plugins/enums/checkout';
import Button from '@/components/elements/Button';

export default {
  name: 'OrderCardNew',
  components: {
    Button,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      order: {},
      deliveries: [],
      products: [],
      tags: [],
      expanded: false,
    }
  },
  async fetch() {
    try {
      const response = await this.$api.profile.getProfileOrder(this.item.id);
      this.order = response.order;
      this.deliveries = response.deliveries;
      this.products = response.deliveries.reduce((acc, item) => [...acc, ...item.products], []);
    } catch (e) {
      if (e.response?.status === 404) {
        console.log({ statusCode: 404, message: e.message })
      }
      console.error(e);
    }
  },
  computed: {
    orderPaymentStatus() {
      return orderPaymentStatus
    },
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    ...mapGetters({
      isAccountOrganization: 'auth/isAccountOrganization',
    }),
    paymentStatus() {
      return this.item.is_canceled ? 'Отменен' : getPaymentStatusName(this.item.payment_status)
    },
    itemTypeName() {
      return orderTypeName[this.item.type] || 'Другое'
    },
    itemStatus() {
      return this.getOrderStatusName(this.item.status)
    },
    isReturned() {
      return this.item.has_returned_items
    },
    actionButtons() {
      const btns = [ 'return', 'payment', 'cancel', 'repeat' ]

      if (this.isAccountOrganization) {
        btns.push('bill')
      }

      return btns
    },
  },
  methods: {
    dateTimeToString,
    getOrderStatusName,
    onInfoUpdate({ order }) {
      this.$emit('update:order', order)
    },
    handleExpandOrder(id) {
      if (this.expanded) {
        this.expanded = false;
      } else {
        this.expanded = true;
      }
    },
    deliveryMethod(method) {
      switch (method) {
        case receiveMethods.DELIVERY:
          return 'Доставка курьером';
        case receiveMethods.PICKUP:
          return 'Доставка в пункт выдачи';
        case receiveMethods.EXPRESS:
          return 'Экспресс доставка';
      }
      return '';
    },
    handlePayClick() {
      window.open(this.order.payment_method?.[0]?.data.paymentUrl, '_blank')
    },
  },
}
</script>
