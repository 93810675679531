<template>
  <section class="order-card-profile">
    <div class="order-card-profile__wrapper">
      <!--      <div class="order-card-profile__img-wrapper">-->
      <!--        <div class="order-card-profile__img">-->
      <!--          <img src="/images/thankyou/shape.png" alt="">-->
      <!--        </div>-->
      <!--      </div>-->

      <h1 class="order-card-profile__order-details-title">
        № {{ order.number }}
      </h1>

      <div class="order-card-profile__order-details-title-container__mobile" @click="handleExpandOrder">
        <h1 class="order-card-profile__order-details-title order-card-profile__order-details-title__mobile">
          № {{ order.number }}
        </h1>
        <div>
          <svg-icon v-if="!expanded" name="chevron-down-grey" width="24" height="24" />
          <svg-icon
            v-else
            name="chevron-down-grey"
            width="24"
            height="24"
            class="order-card-profile__order-details-expand-rotate-180"
          />
        </div>
      </div>


      <!--      <h1 class="order-card-profile__title font font_title-l font_bold font_uppercase">-->
      <!--        Заказ {{ order.number }} <span class="order-card-profile__title_status">-->
      <!--            {{ orderStatusName }}-->
      <!--        </span>-->
      <!--      </h1>-->
      <div class="order-card-profile__order-details_table-wrapper">
        <table class="order-card-profile__order-details">
          <tr class="order-card-profile__order-details_header">
            <th>Статус</th>
            <th>№ заказа</th>
            <th>Дата заказа</th>
            <th>Сумма заказа</th>
            <!--            <th>Чек об оплате</th>-->
          </tr>
          <tr class="order-card-profile__order-details_body">
            <td class="order-card-profile__order-details_status-new">
              {{ itemStatus }}
            </td>
            <td>{{ order.number }}</td>
            <td>{{ dateFormatKz(item.created_at, true, false, 'от') }}</td>
            <td>{{ getCurrency(order.price?.value) }}</td>
            <!--            <td><a href="#" @click.prevent="handleClickViewCheck">Посмотреть чек</a></td>-->
          </tr>
        </table>
      </div>

      <div class="order-card-profile__order-details__block">
        <div class="order-card-profile__order-details__block-row">
          <div class="order-card-profile__order-details__block-head-title">
            Статус
          </div>
          <div
            class="order-card-profile__order-details__block-head-value
          order-card-profile__order-details__block-head-value-green"
            :class="{
              'profile-order-card__product-status-not-paid': item.payment_status !== orderPaymentStatus.PAID &&
                !item.is_canceled && order.payment_method?.[0]?.data.paymentUrl
            }"
          >
            {{ paymentStatus }}
          </div>
        </div>
        <div class="order-card-profile__order-details__block-row">
          <div class="order-card-profile__order-details__block-head-title">
            № заказа
          </div>
          <div class="order-card-profile__order-details__block-head-value">
            {{ order.number }}
          </div>
        </div>
        <div class="order-card-profile__order-details__block-row">
          <div class="order-card-profile__order-details__block-head-title">
            Дата заказа
          </div>
          <div class="order-card-profile__order-details__block-head-value">
            {{ dateFormatKz(item.created_at, true, false, 'от') }}
          </div>
        </div>
        <div class="order-card-profile__order-details__block-row">
          <div class="order-card-profile__order-details__block-head-title">
            Сумма заказа
          </div>
          <div class="order-card-profile__order-details__block-head-value">
            {{ getCurrency(order.price?.value) }}
          </div>
        </div>
        <div v-if="expanded" class="order-card-profile__order-details__block-show-check-container">
          <!--          <button-->
          <!--            type="button"-->
          <!--            class="order-card-profile__order-details__block-show-check"-->
          <!--            @click="handleClickViewCheck"-->
          <!--          >-->
          <!--            Посмотреть чек-->
          <!--          </button>-->
        </div>
        <table v-if="expanded" class="order-card-profile__order-details__block-table">
          <tr>
            <td class="order-card-profile__order-details__block-table-title">
              Получатель
            </td>
            <td class="order-card-profile__order-details__block-table-value">
            </td>
          </tr>
          <template v-for="delivery in deliveries">
            <tr :key="delivery.id">
              <td class="order-card-profile__order-details__block-table-title">
                Способ доставки
              </td>
              <td class="order-card-profile__order-details__block-table-value">
                {{ deliveryMethod(delivery.delivery_method) }}<br />
                {{ delivery.delivery_address.address_string }}
              </td>
            </tr>
            <tr :key="delivery.id">
              <td class="order-card-profile__order-details__block-table-title">
                Способ оплаты
              </td>
              <td class="order-card-profile__order-details__block-table-value">
                {{ delivery.point?.payment }}
              </td>
            </tr>
          </template>
        </table>

        <div
          v-if="products && expanded"
          class="order-card-profile__order-products order-card-profile__order-products-mobile"
        >
          <div v-for="p in products" :key="p.id" class="order-card-profile__order-product">
            <div class="order-card-profile__order-product-photo">
              <img :src="p.image.url.small" :alt="p.image.caption" />
              <div class="order-card-profile__order-product-quantity">
                x{{ p.qty.slice('.')[0] }}
              </div>
            </div>
            <div class="order-card-profile__order-product-description">
              <div class="order-card-profile__order-product-description-brand">
                {{ p.brand }}
              </div>
              <div class="order-card-profile__order-product-description-name">
                {{ p.name }}
              </div>
              <div v-if="false">
                30 мл
              </div>
            </div>
            <div class="order-card-profile__order-product-price">
              <div v-if="p.oldPrice" class="order-card-profile__order-product-price-old">
                {{ getCurrency(p.oldPrice) }}
              </div>
              <div class="order-card-profile__order-product-price-current">
                {{ getCurrency(p.price?.value) }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="expanded"
          class="
        order-card-profile__order-details-info
        order-card-profile__order-details-info-mobile"
        >
          <div class="order-card-profile__order-details-info-title">
            Информация о заказе
          </div>
          <div class="order-card-profile__order-details-info-footer">
            <div class="order-card-profile__order-details-info-footer-row">
              <div class="order-card-profile__order-details-info-label">
                Сумма заказа
              </div>
              <div class="order-card-profile__order-details-info-info">
                {{ getCurrency(order.price?.value) }}
              </div>
            </div>
            <div class="order-card-profile__order-details-info-footer-row">
              <div class="order-card-profile__order-details-info-label">
                Скидка
              </div>
              <div
                class="
              order-card-profile__order-details-info-info
              order-card-profile__order-details-info-info-discount"
              >
                20 000 T
              </div>
            </div>
            <div class="order-card-profile__order-details-info-footer-row">
              <div class="order-card-profile__order-details-info-label">
                Доставка
              </div>
              <div class="order-card-profile__order-details-info-info">
                {{ getCurrency(order.delivery_price.value) }}
              </div>
            </div>
            <div class="order-card-profile__order-details-info-footer-row">
              <div class="order-card-profile__order-details-info-label">
                Сумма к оплате
              </div>
              <div class="order-card-profile__order-details-info-info">
                {{ getCurrency(order.price?.value + order.delivery_price.value) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="item.payment_status !== orderPaymentStatus.PAID &&
          !item.is_canceled && order.payment_method?.[0]?.data.paymentUrl"
        class="profile-order-card__pay-button-wrapper"
      >
        <Button
          class="profile-order-card__pay-button profile-order-card__pay-button-mobile"
          @click="handlePayClick"
        >
          Оплатить
        </Button>
      </div>

      <div class="order-card-profile__order-details_info-block">
        <table class="order-card-profile__order-details_info-table">
          <tr>
            <th>Получатель</th>
            <th>Способ доставки</th>
            <th>Способ оплаты</th>
          </tr>
          <tr>
            <td>фвфывв фывфвыв</td>
            <td>фвыапывап</td>
            <td>вапыапыа</td>
          </tr>
        </table>

        <div class="order-card-profile__order-content">
          Состав заказа
        </div>

        <div
          v-if="products && expanded"
          class="
        order-card-profile__order-products
        order-card-profile__order-products-desktop"
        >
          <div v-for="p in products" :key="p.id" class="order-card-profile__order-product">
            <div class="order-card-profile__order-product-photo">
              <img :src="p.image.url.small" :alt="p.image.caption" />
              <div class="order-card-profile__order-product-quantity">
                x{{ p.qty.slice('.')[0] }}
              </div>
            </div>
            <div class="order-card-profile__order-product-description">
              <div>{{ p.name }}</div>
              <div v-if="false">
                30 мл
              </div>
            </div>
            <div class="order-card-profile__order-product-price">
              <div v-if="p.oldPrice" class="order-card-profile__order-product-price-old">
                {{ getCurrency(p.oldPrice) }}
              </div>
              <div class="order-card-profile__order-product-price-current">
                {{ getCurrency(p.price?.value) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="order-card-profile__button-wrapper">-->
      <!--        <OrderActions-->
      <!--          class="order-card-profile__button"-->
      <!--          :order="order"-->
      <!--          :buttons="actionButtons"-->
      <!--        />-->
      <!--        <Button-->
      <!--          :to="{-->
      <!--            name: 'profile.order',-->
      <!--            params: {-->
      <!--              id: order.id-->
      <!--            }-->
      <!--          }"-->
      <!--          class="order-card-profile__button"-->
      <!--        >-->
      <!--          Посмотреть заказ-->
      <!--        </Button>-->
      <!--        <Button-->
      <!--          :to="{ name: 'main' }"-->
      <!--          theme="secondary"-->
      <!--          class="order-card-profile__button"-->
      <!--        >-->
      <!--          На главную-->
      <!--        </Button>-->
      <!--      </div>-->
    </div>
  </section>
</template>
<script>

import {mapGetters} from 'vuex';
import {getOrderStatusName, getPaymentStatusName} from '~/plugins/helpers/order';
import {orderPaymentStatus, orderTypeName} from '~/plugins/enums/order';
import {dateFormatKz} from '~/plugins/formatting';
import './index.scss';
import {receiveMethods} from '~/plugins/enums/checkout';
import Button from '@/components/elements/Button';

export default {
  name: 'OrderCardRedesigned',
  components: {
    Button,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      order: {},
      deliveries: [],
      products: [],
      tags: [],
      expanded: false,
    }
  },
  async fetch() {
    try {
      const response = await this.$api.profile.getProfileOrder(this.item.id);
      this.order = response.order;
      this.deliveries = response.deliveries;
      this.products = response.deliveries.reduce((acc, item) => [...acc, ...item.products], []);
    } catch (e) {
      if (e.response?.status === 404) {
        console.log({ statusCode: 404, message: e.message })
      }
      console.error(e);
    }
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    ...mapGetters({
      isAccountOrganization: 'auth/isAccountOrganization',
    }),
    paymentStatus() {
      return this.item.is_canceled ? 'Отменен' : getPaymentStatusName(this.item.payment_status)
    },
    itemTypeName() {
      return orderTypeName[this.item.type] || 'Другое'
    },
    itemStatus() {
      return this.getOrderStatusName(this.item.status)
    },
    isReturned() {
      return this.item.has_returned_items
    },
    actionButtons() {
      const btns = [ 'return', 'payment', 'cancel', 'repeat' ]

      if (this.isAccountOrganization) {
        btns.push('bill')
      }

      return btns
    },
    orderPaymentStatus() {
      return orderPaymentStatus;
    },
  },
  methods: {
    getOrderStatusName,
    dateFormatKz,
    onInfoUpdate({ order }) {
      this.$emit('update:order', order)
    },
    handleExpandOrder(id) {
      if (this.expanded) {
        this.expanded = false;
      } else {
        this.expanded = true;
      }
    },
    deliveryMethod(method) {
      switch (method) {
        case receiveMethods.DELIVERY:
          return 'Доставка курьером';
        case receiveMethods.PICKUP:
          return 'Доставка в пункт выдачи';
        case receiveMethods.EXPRESS:
          return 'Экспресс доставка';
      }
      return '';
    },
    handlePayClick() {
      window.open(this.order.payment_method?.[0]?.data.paymentUrl, '_blank')
    },
  },
}
</script>
