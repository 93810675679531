<template>
  <Button
    v-if="!showPreloader"
    :full="full"
    :theme="theme"
    :outline="outline"
    :class="btnClass"
    v-on="handlers"
  >
    <slot />
  </Button>
  <div v-else :class="preloaderClass">
    <Spinner class="preloader" theme="simple" width="48px" height="48px" show />
  </div>
</template>

<script>
import Button from '@/components/elements/Button.vue';
import Spinner from '@/components/elements/LoaderSpinner.vue';

export default {
  name: 'ShowMoreButton',
  components: {
    Button,
    Spinner,
  },
  props: {
    showPreloader: {
      type: Boolean,
      default: false,
    },
    btnClass: {
      type: String,
      default: '',
    },
    preloaderClass: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    handlers() {
      const keys = Object.keys(this.$listeners);
      const handlers = {};

      keys.forEach((k) => (handlers[k] = (e) => this.$emit(k, e)));
      handlers.click = this.onBtnClick;

      return handlers;
    },
  },
  methods: {
    onBtnClick(e) {
      e.stopPropagation();
      this.$emit('click', e);
    },
  },
};
</script>
